// src/client/schema.generated.ts
var schema = {
  "sendDataHubListingComment": {
    "method": "POST",
    "path": "/datahub/:dataId/message",
    "formData": false
  },
  "createDataHubListing": {
    "method": "POST",
    "path": "/datahub/create",
    "formData": true
  },
  "getDataHubManagementData": {
    "method": "GET",
    "path": "/datahub/:dataId/mgmt",
    "formData": false
  },
  "addDataHubContracts": {
    "method": "POST",
    "path": "/datahub/:dataId/commit",
    "formData": false
  },
  "updateDataHubContracts": {
    "method": "POST",
    "path": "/datahub/:dataId/commit-edit",
    "formData": false
  },
  "updateDataHubMetadata": {
    "method": "POST",
    "path": "/datahub/:dataId/metadata",
    "formData": false
  },
  "adminArchiveOrganization": {
    "method": "POST",
    "path": "/admin/organizations/:organization_id/archive",
    "formData": false
  },
  "adminUnarchiveOrganization": {
    "method": "POST",
    "path": "/admin/organizations/:organization_id/unarchive",
    "formData": false
  },
  "projectListOverview": {
    "method": "POST",
    "path": "/v4/internal/projects/overview",
    "formData": false
  },
  "projectMeta": {
    "method": "GET",
    "path": "/v4/internal/projects/:project_slug",
    "formData": false
  },
  "projectsMeta": {
    "method": "GET",
    "path": "/v4/internal/projects",
    "formData": false
  },
  "projectList": {
    "method": "POST",
    "path": "/v4/internal/projects/list",
    "formData": false
  },
  "projectComparison": {
    "method": "GET",
    "path": "/v4/internal/projects/comparison",
    "formData": false
  },
  "projectMetricsComparison": {
    "method": "POST",
    "path": "/v4/internal/projects/comparison",
    "formData": false
  },
  "projectNews": {
    "method": "GET",
    "path": "/v4/internal/projects/:project_slug/news",
    "formData": false
  },
  "getCompositionsAvailability": {
    "method": "POST",
    "path": "/v4/internal/metrics/compositions-availability",
    "formData": false
  },
  "getCompositionsMetricAvailability": {
    "method": "POST",
    "path": "/v4/internal/metrics/compositions-metric-availability",
    "formData": false
  },
  "getCompositionsChainAvailability": {
    "method": "POST",
    "path": "/v4/internal/metrics/compositions-metric-availability",
    "formData": false
  },
  "getChains": {
    "method": "GET",
    "path": "/v4/internal/chains",
    "formData": false
  },
  "getChainsAvailability": {
    "method": "GET",
    "path": "/v4/internal/chains/availability",
    "formData": false
  },
  "getMetricAvailability": {
    "method": "POST",
    "path": "/v4/internal/metrics/availability",
    "formData": false
  },
  "getMetricProjectAvailability": {
    "method": "GET",
    "path": "/v4/internal/metrics/:metric_id/project-availability",
    "formData": false
  },
  "getMetricsList": {
    "method": "GET",
    "path": "/v4/internal/metrics/configuration",
    "formData": false
  },
  "getTimeseries": {
    "method": "POST",
    "path": "/v4/internal/metrics/timeseries",
    "formData": false
  },
  "getMetricsAggregations": {
    "method": "POST",
    "path": "/v4/internal/metrics/aggregations",
    "formData": false
  },
  "getMetricsBreakdown": {
    "method": "POST",
    "path": "/v4/internal/metrics/breakdown",
    "formData": false
  },
  "getMetricsEcosystemsAvailability": {
    "method": "GET",
    "path": "/v4/internal/metrics/ecosystems-availability",
    "formData": false
  },
  "getMetricsEcosystems": {
    "method": "POST",
    "path": "/v4/internal/metrics/ecosystems",
    "formData": false
  }
};

// src/client/client.ts
var TTClient = class {
  baseUrl;
  getToken;
  appVersion;
  rateLimitJwt;
  constructor({
    baseUrl,
    getToken,
    appVersion,
    rateLimitJwt
  }) {
    this.baseUrl = baseUrl;
    this.getToken = getToken;
    this.appVersion = appVersion;
    this.rateLimitJwt = rateLimitJwt;
  }
  buildRequest(endpoint, options) {
    const endpointDef = schema[endpoint];
    let url = new URL(
      endpointDef.path.replace(/\/{2,}/g, "/"),
      this.baseUrl
    ).toString();
    if (options?.params) {
      Object.entries(options.params).forEach(([key, value]) => {
        url = url.replace(`:${key}`, encodeURIComponent(String(value)));
      });
    }
    if (options?.query) {
      const urlObj = new URL(url);
      Object.entries(options.query).forEach(([k, v]) => {
        if (Array.isArray(v)) {
          v.forEach((item) => {
            urlObj.searchParams.append(`${k}[]`, String(item));
          });
        } else {
          urlObj.searchParams.append(k, String(v));
        }
      });
      url = urlObj.toString();
    }
    const hasFormData = !!endpointDef.formData;
    const headers = new Headers();
    headers.append(
      "Content-Type",
      hasFormData ? "multipart/form-data" : "application/json"
    );
    if (this.rateLimitJwt) {
      headers.append("x-tt-terminal-jwt", this.rateLimitJwt);
    }
    if (this.appVersion) {
      headers.append("x-app-version", this.appVersion);
    }
    const apiToken = this.getToken();
    if (apiToken) {
      headers.append("Authorization", `Bearer ${apiToken}`);
    }
    if (typeof window !== "undefined") {
      headers.append("x-app-path", window.location.pathname);
    }
    const init = {
      method: endpointDef.method,
      headers,
      credentials: "include"
      // By default always send cookies for auth
    };
    if (options?.body) {
      init.body = hasFormData ? this.createFormData(options.body) : JSON.stringify(options.body);
    }
    return { url, init };
  }
  async request(endpoint, options) {
    const { url, init } = this.buildRequest(endpoint, options);
    const response = await fetch(url, init);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }
  createFormData(data) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value !== void 0) {
        formData.append(key, JSON.stringify(value));
      }
    });
    return formData;
  }
};
export {
  TTClient
};
